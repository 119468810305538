<template>
  <div>
    <b-card
      class="mt-10"
      :title="$t('Explanation')"
    >
      <vs-row>

        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="ellipsis-v"
            color="light-primary"
            icon-color-bg="primary"
            icon-color="null"
          >
            {{ $t('More Options') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >

          <vx-badge
            pack="fas"
            icon="arrow-down"
            color="light-success"
            icon-color-bg="success"
            icon-color="null"
          >
            {{ $t('In coming') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="arrow-up"
            color="light-danger"
            icon-color-bg="danger"
            icon-color="null"
          >
            {{ $t('Out Coming') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >

          <vx-badge
            pack="fas"
            icon="exchange-alt"
            color="light-warning"
            icon-color-bg="warning"
            icon-color="null"
          >
            {{ $t('Balance') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="trash-alt"
            color="light-danger"
            icon-color-bg="danger"
            icon-color="null"
          >
            {{ $t('Delete Cashier') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >

          <vx-badge
            pack="fas"
            icon="edit"
            color="light-success"
            icon-color-bg="success"
            icon-color="null"
          >
            {{ $t('Edit Cashier') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="far"
            icon="calendar-plus"
            color="light-success"
            icon-color-bg="success"
            icon-color="null"
          >
            {{ $t('Created At') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="far"
            icon="calendar-plus"
            color="light-primary"
            icon-color-bg="primary"
            icon-color="null"
          >

            {{ $t('last Transactions') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="calendar-alt"
            color="light-warning"
            icon-color-bg="warning"
            icon-color="null"
          >
            {{ $t('Updated At') }}
          </vx-badge>
        </vs-col>
      </vs-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "CashierExplanations",
}

</script>

<style scoped>

</style>
