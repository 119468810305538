<template>
  <div>
    <b-modal
      v-model="localStatus"
      hide-footer
      :title=" $t('Update')+' '+ $t('Cashier')"
    >
      <vs-row vs-w="12">
        <vs-col
          vs-w="12"
          class="mt-3"
        >
          <vx-input
            v-model="localCashierName"
            :label="$t('Name')"
            :errors="errors.name"
          />
        </vs-col>
        <vs-col
          class="mt-3"
        >
          <hr>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-w="12"
          vs-justify="flex-end"
        >
          <vs-button @click="saveCashier">
            {{ $t('Save') }}
          </vs-button>
        </vs-col>
      </vs-row>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: {
    cashierId: {
      default: null,
      type: Number,
    },
    value: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      errors: [],
      localCashierName: '',
    }
  },
  computed: {
    localStatus: {
      get() {
        return this.value
      },
      set(val) {
        this.localCashierName = ''
        this.errors = {}
        this.$emit('input', val)
      },
    },
    cashierName() {
      if (this.$store.getters['cashier/cashier']) return this.$store.getters['cashier/cashier'].name
      return ''
    },
  },
  watch: {
    cashierName(val) {
      this.localCashierName = val
    },
    value(val) {
      if (val === true && this.cashierId) this.getData()
    },
  },
  methods: {
    getData() {
      this.$store.state.cashier.cashier = {}
      this.$store.dispatch('cashier/getCashier', this.cashierId)
    },
    saveCashier() {
      this.$store.dispatch('cashier/updateCashier', { name: this.localCashierName, id: this.cashierId }).then(() => {
        this.localStatus = false
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
  },
}
</script>
