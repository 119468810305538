<template>
  <div>
    <b-modal
      v-model="localStatus"
      hide-footer
      :title="$t('Add New Cashier')"
    >
      <vs-row vs-w="12">
        <vs-col
          vs-w="12"
          class="mt-3"
        >
          <vx-input
            v-model="localCashierName"
            :label="$t('Name')"
            :errors="errors.name"
          />
        </vs-col>
        <vs-col
          class="mt-3"
        >
          <hr>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-w="12"
          vs-justify="flex-end"
        >
          <vs-button @click="saveCashier">
            {{ $t('Save') }}
          </vs-button>
        </vs-col>
      </vs-row>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: ['value'],
  data() {
    return {
      errors: [],
      localCashierName: '',
    }
  },
  computed: {
    localStatus: {
      get() {
        return this.value
      },
      set(val) {
        this.localCashierName = ''
        this.errors = {}
        this.$emit('input', val)
      },
    },
  },
  methods: {
    saveCashier() {
      this.$store.dispatch('cashier/storeCashier', { name: this.localCashierName }).then(() => {
        this.localStatus = false
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
  },
}
</script>
