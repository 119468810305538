<template>
  <div>
    <create-cashier
      v-model="CreatCashierPopup"
    />
    <edit-cashier
      v-model="EditCashierPopup"
      :cashier-id="cashierId"
    />
    <new-transaction
      v-model="newTransactionStatus"
      :cashier-id="cashierId"
    />
    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-button
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-plus"
          @click="addNewCashier"
        >
          {{ $t('Create Cashier') }}
        </vs-button>
      </vs-col>
      <vs-col
        vs-w="6"
        vs-type="flex"
        vs-align="flex-end"
        vs-justify="flex-end"
      >
        <vs-button
          :to="{ name: 'trashed_cashiers'}"
          color="danger"
          type="gradient"
          icon="delete_forever"
        >{{ $t('Trash') }}
        </vs-button>
      </vs-col>

      <vs-col
        vs-w="12"
        class="mt-5"
      >
        <div
          id="data-list-list-view"
          class="data-list-container"
        >

          <vs-pagination-table
            model_type="cashier"
            :data="cashiers"
            :table_name="$t('Cashiers')"
          >
            <template slot-scope="{data}">
              <template>
                <vs-th sort-key="id">
                  {{ $t('ID') }}
                </vs-th>
                <vs-th sort-key="name">
                  {{ $t('Name') }}
                </vs-th>
                <vs-th>{{ $t('Incomming') }}</vs-th>
                <vs-th>{{ $t('Outcomming') }}</vs-th>
                <vs-th>{{ $t('Balance') }}</vs-th>
                <vs-th>{{ $t('Last Transaction') }}</vs-th>
                <vs-th>{{ $t('Dates') }}</vs-th>
                <vs-th>{{ $t('Options') }}</vs-th>
              </template>
              <template>
                <vs-tr
                  v-for="(tr, index) in data"
                  :key="index"
                >
                  <vs-td>
                    <vs-chip
                      transparent
                      color="primary"
                    >
                      {{ tr.id }}
                    </vs-chip>
                  </vs-td>
                  <vs-td>
                    {{ tr.name }}
                  </vs-td>
                  <vs-td>
                    <font-awesome-icon
                      color="rgb(var(--vs-success))"
                      :icon="['fas', 'arrow-down']"
                    />
                    {{ tr.income }}
                  </vs-td>
                  <vs-td>
                    <font-awesome-icon
                      color="rgb(var(--vs-danger))"
                      :icon="['fas', 'arrow-up']"
                    />
                    {{ tr.outcome }}
                  </vs-td>
                  <vs-td>
                    <font-awesome-icon
                      color="rgb(var(--vs-warning))"
                      :icon="['fas', 'exchange-alt']"
                    />
                    {{ tr.balance }}
                  </vs-td>
                  <vs-td>
                    <VxTooltip
                      :color="tr.last_transaction ? 'primary' : 'warning'"
                    >
                      <template #title>
                        {{ $t('Last transaction') }}
                      </template>
                      <template #text>
                        <span v-if="tr.last_transaction_from">

                          {{ tr.last_transaction }}
                        </span>

                        <span v-else>{{ $t("no transaction yet") }}</span>
                      </template>
                      <vx-badge
                        pack="far"
                        icon="calendar-plus"
                        :color="tr.last_transaction ? 'light-primary' : 'light-warning'"
                        :icon-color-bg="tr.last_transaction ? 'primary' : 'warning'"
                        icon-color="null"
                      >
                        <span v-if="tr.last_transaction_from"> {{ tr.last_transaction_from }}
                        </span>

                        <span v-else> {{ $t("no transaction yet") }}</span>
                      </vx-badge>
                    </VxTooltip>
                  </vs-td>
                  <vs-td>
                    <div style="width: max-content">

                      <VxTooltip
                        color="success"
                      >
                        <template #title>
                          {{ $t('Created_At') }}
                        </template>
                        <template #text>
                          {{ tr.created_at }}
                        </template>
                        <vx-badge
                          pack="far"
                          icon="calendar-plus"
                          color="light-success"
                          icon-color-bg="success"
                          icon-color="null"
                        >
                          {{ tr.created_from }}
                        </vx-badge>
                      </VxTooltip>
                    </div>
                    <div
                      style="width: max-content"
                      class="mt-1"
                    >
                      <VxTooltip
                        color="warning"
                      >
                        <template #title>
                          {{ $t('Updated_At') }}
                        </template>
                        <template #text>
                          {{ tr.updated_at }}
                        </template>
                        <vx-badge
                          pack="fas"
                          icon="calendar-alt"
                          color="light-warning"
                          icon-color-bg="warning"
                          icon-color="null"
                        >
                          {{ tr.updated_from }}
                        </vx-badge>
                      </VxTooltip>
                    </div>
                  </vs-td>
                  <vs-td>
                    <div class="auto-cols-max">
                      <a
                        class="mr-1"
                        href="javascript:void(0)"
                        @click="editCashier(tr.id)"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-success))"
                          :icon="['fas', 'edit']"
                        />
                      </a>
                      <a
                        class="mr-1"
                        href="javascript:void(0)"
                        @click="removeCashierAlert(tr.id)"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-danger))"
                          :icon="['fas', 'trash-alt']"
                        />
                      </a>

                      <span>
                        <vs-dropdown vs-trigger-click>
                          <a href="#">
                            <font-awesome-icon
                              color="rgb(var(--vs-primary))"
                              :icon="['fas', 'ellipsis-v']"
                            />
                          </a>

                          <vs-dropdown-menu>
                            <vs-dropdown-item @click="TransferMoney(tr.id)">
                              {{ $t('Transfer money') }}
                            </vs-dropdown-item>

                            <vs-dropdown-item
                              divider
                              :to="{
                                name: 'cashier-transactions',
                                params: { cashierId: tr.id }
                              }"
                            >
                              {{ $t('View All Transaction') }}
                            </vs-dropdown-item>
                            <vs-dropdown-item hidden>
                              {{ $t('post this cashier') }}
                            </vs-dropdown-item>
                            <vs-dropdown-item hidden>
                              {{ $t('view posting operation') }}
                            </vs-dropdown-item>
                            <vs-dropdown-item hidden>
                              {{ $t('Cashier Details') }}
                            </vs-dropdown-item>
                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </span>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </template>
          </vs-pagination-table>
        </div>
      </vs-col>
    </vs-row>
    <CashierExplanations />
  </div>
</template>

<script>
import NewTransaction from './NewTransaction.vue'
import CreateCashier from './CreateCashier.vue'
import EditCashier from './EditCashier.vue'
import CashierExplanations from './components/cashierExplantion.vue'

export default {
  components: {
    NewTransaction,
    CashierExplanations,
    CreateCashier,
    EditCashier,
  },
  data() {
    return {
      CreatCashierPopup: false,
      EditCashierPopup: false,
      newTransactionStatus: false,
      cashierId: null,
      cashier: {},
    }
  },
  computed: {
    cashiers() {
      return this.$store.getters['cashier/cashiers']
    },
  },
  created() {
  },
  methods: {
    removeCashierAlert($val) {
      this.cashierId = $val
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: this.$t('Are you sure you want to delete this Cashier'),
        accept: this.acceptAlert,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel'),
      })
    },
    acceptAlert() {
      this.$store.dispatch('cashier/DeleteCashier', this.cashierId)
    },
    addNewCashier() {
      this.cashierId = ''
      this.CreatCashierPopup = true
    },
    editCashier(cashId) {
      this.cashierId = cashId
      this.EditCashierPopup = true
    },
    TransferMoney(val) {
      this.cashierId = val
      this.newTransactionStatus = true
    },
  },
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
